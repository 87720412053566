import React from 'react';
import { Heading, Text } from '@atoms';
import { Link } from 'gatsby'
import { InlineLink } from '../Resources/ResourceCardInlineLink';
import {
  FeaturedItemWrapper,
  FeaturedContent,
  FeaturedText,
  FeaturedPhoto,
  FeaturedHeading,
  FeaturedDescription
} from "./styles/PodcastListingHero.styled"
import { Category, LinkWrap } from '../Card/styles/PodcastCard.styled';
import { getCardSlug } from '../../utils/getCardSlug';

import OptimizedImage from '../../utils/optimizedImage';

const FeaturedPodcast = ({ item }) => {
  let cardSlug = getCardSlug(item)
  let category = item?.category[0]?.title.toLowerCase()
  let cardImage = item?.infographicImage ?? item?.featuredImage

  return (
    <FeaturedItemWrapper href={cardSlug}>
      <Link to={cardSlug}>
        <FeaturedContent>
          <FeaturedText>
            <Category>
              Featured Podcast
            </Category>
            {item?.title && 
              <FeaturedHeading>
                {item?.title}
              </FeaturedHeading>
            }
            {item?.description && 
              <FeaturedDescription>
                {item?.description?.description}
              </FeaturedDescription>
            }
            {category &&
              <LinkWrap>
                <InlineLink category={category}/>
              </LinkWrap>
            }
          </FeaturedText>
          {cardImage && (
            <FeaturedPhoto>
              <OptimizedImage image={cardImage?.gatsbyImageData} src={cardImage?.file?.url} alt={cardImage?.file?.alt || "Featured Image"}/>
            </FeaturedPhoto>
          )}
        </FeaturedContent>
      </Link>
    </FeaturedItemWrapper>
  );
};

export default FeaturedPodcast
