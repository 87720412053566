import React, { useState, useEffect } from "react";
import { PodcastCard } from "../Card/PodcastCard";
import { getCardSlug } from "../../utils/getCardSlug";

import { color } from '@atoms/colors'

import ComponentPagination from "../Pagination/Pagination";
import { paginator } from "../../utils/paginatorFunction";
import useScreenResponsive from "../../utils/screenResponsive";
import { CardDeckGrid, CardDeckWrapper, Content, Heading } from "./styles/PodcastListingCarDeck.styled";

const PodcastListingCardDeck = ({ podcasts }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPostsPerPage, setMaxPosts] = useState(6);

  const isMobile = useScreenResponsive(576)

  useEffect(() => {
    isMobile? setMaxPosts(3) : setMaxPosts(6)
  }, [isMobile])

  let paginatedPostData = paginator(podcasts, currentPage, maxPostsPerPage)

  let paginatedPosts = paginatedPostData?.data?.map((podcast) => {
    let cardSlug = getCardSlug(podcast)

    return (
      <PodcastCard 
        gatsbyImage={podcast?.featuredImage?.gatsbyImageData}
        title={podcast?.title}
        featuredImg={podcast?.featuredImage}
        description={podcast?.description?.description}
        categories={podcast?.category}
        link={cardSlug}
        border={color.grey[200]}
      />
    )
  })

  return (
    <CardDeckWrapper>
      <Content>
        <Heading>Latest Episodes</Heading>
        <CardDeckGrid>
          {paginatedPosts}
        </CardDeckGrid>
        <ComponentPagination 
          current={currentPage} 
          total={podcasts.length}
          pageSize={maxPostsPerPage}
          func={setCurrentPage}
        />
      </Content>
    </CardDeckWrapper>
  )
}

export default PodcastListingCardDeck
