import React from 'react';
import { Link } from "gatsby";
import cardPlaceholderImg from './card-placeholder.jpg';
import { Container, CardContainer, ImageWrapper, Category, Heading, Text, LinkWrap, ContentWrap } from './styles/PodcastCard.styled';
import { InlineLink } from '../Resources/ResourceCardInlineLink';
import OptimizedImage from "../../utils/optimizedImage"
import { ConditionalWrapper } from '../../atoms/ConditionalWrapper';

function truncate(input) {
  if (input.length > 130) {
    return input.substring(0, 130) + '...';
  }
  return input;
};

export const PodcastCard = ({ title, categories, description, link, gatsbyImage, featuredImg, border, alt }) => {
  let category
  if (categories) {
    category = categories[0]?.title.toLowerCase();
  }
  return (
    <Container>
      <ConditionalWrapper
        condition={link}
        wrapper={children => <Link style={{ display: 'block', padding: '0px', textDecoration: 'none', height: '100%' }} to={link}>{children}</Link>}
      >
        <CardContainer
          data-comp={PodcastCard.displayName}
          border={border}
        >
          <ImageWrapper>
            <OptimizedImage 
              image={gatsbyImage}
              src={featuredImg || cardPlaceholderImg} 
              alt={alt || title}
            />
          </ImageWrapper>
          <ContentWrap>
            {categories && <Category>{categories[0]?.title}</Category>}
            {title && <Heading>{title}</Heading>}
            {description && <Text>{truncate(description)}</Text> }
          </ContentWrap>
          {categories && 
            <LinkWrap>
              <InlineLink category={category} />
            </LinkWrap>
          }
        </CardContainer>
      </ConditionalWrapper>
    </Container>
  );
};
