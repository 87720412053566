import styled from "styled-components";
import { color } from "../../../atoms/colors"
import { font } from "../../../atoms/typography"

export const Container = styled.div`
  background-color: ${color.common.white};
  border-radius: 8px;
  transition: box-shadow 300ms ease;
  :hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  height: 100%;
`

export const CardContainer = styled.div`
  padding: 24px 22px;
  border-radius: 8px;
  border: 1px solid ${props => (props?.border && props?.border) ?? color.common.white};
  color: ${color.grey[900]};
  display: flex;
  gap: 24px;
  flex-direction: column;
  height: 100%;
`

export const ImageWrapper = styled.div`
  aspect-ratio: 163 / 70;
  border-radius: 8px;
  img {
    border-radius: 8px;
    aspect-ratio: 163 / 70;
  }
`

export const ContentWrap = styled.div`
  display: grid;
  gap: 8px;
`

export const Category = styled.p`
  ${font("kicker", "sm", "700")}
  color: ${color.grey[900]};
  padding: 5px 10px;
  width: fit-content;
  display: inline-block;
  white-space: nowrap;
  background-color: ${color.common.nobiBlue};
  border-radius: 35px;
  margin-bottom: 8px;
`

export const Heading = styled.h3`
  ${font("display", "xxs", "700")}
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const Text = styled.p`
  ${font("text", "reg", "400")}
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const LinkWrap = styled.div`
  ${font("text", "reg", "700")}
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: ${color.primary[900]};
  transition: color ease 150ms;
  :hover {
    color: ${color.common.hoverBlue};
  }
`
