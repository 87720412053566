import React from "react"
import { Layout } from "@components/Layout"
import ConversionPanel from "@components/ConversionPanel/ConversionPanel"
import { graphql } from "gatsby"
import PodcastListingCardDeck from "@components/Podcast/PodcastListingCardDeck"
import PodcastListingHero from "../../components/Podcast/PodcastListingHero"
import { CardDeck } from "../../components/CardDeck"
import Seo from "../../components/Seo/Seo"

export default function PodcastListingPage({ data }) {
  const {
    allContentfulResource,
    contentfulComponentConversionPanel,
    contentfulComponentCardDeck,
    contentfulSeo
  } = data

  const podcasts = allContentfulResource?.nodes
  const seo = contentfulSeo
  const cards = contentfulComponentCardDeck?.post
  
  return (
    <Layout>
      <Seo 
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        metaTags={seo?.metaTags}
      />
      <PodcastListingHero featuredPodcast={podcasts[0]}/>
      <PodcastListingCardDeck podcasts={podcasts} />
      <CardDeck type="cta-cards" podcastListing cards={cards}/>
      <ConversionPanel component={contentfulComponentConversionPanel}/>
    </Layout>
  )
}

export const podcastListingQuery = graphql`
  query podcastListingQuery {
    contentfulSeo(id: {eq: "f4d5e8a6-ef6f-5bbc-8be9-9a6816d7a5a1"}) {
      graphImage {
        description
        title
        file {
          url
        }
      }
      seoTitle
      seoDescription
      canonical
      noIndex
      metaTags
      article
    }
    allContentfulResource(
      filter: {category: {elemMatch: {title: {eq: "Podcast"}}}, isInternalPodcast: {eq: true}}
      sort: {fields: publishDate, order: DESC}
      ) {
      nodes {
        __typename
        id
        title
        slug
        publishDate(formatString: "YYYYMMDD")
        description {
          description
        }
        category {
          title
          backgroundColor
          fontColor
        }
        featuredImage {
          gatsbyImageData
          file {
            url
          }
        }
        podcastEmbed {
          podcastEmbed
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "d22fc7e9-4299-5ebc-9707-2cf355b06051" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      noTopPadding
      noBottomPadding
      headingSize
      heading
      type
    }
    contentfulComponentCardDeck(
      id: { eq: "23fb38c3-9194-5898-907c-4a9f9b31b554" }
    ) {
      __typename
      id
      internalName
      type
      cardType
      backgroundColor
      kicker
      slide2Kicker
      headingSize
      heading
      slide2Heading
      noTopPadding
      noBottomPadding
      subheading {
        subheading
      }
      slide2Subheading {
        slide2Subheading
      }
      post {
        ... on ContentfulResource {
          __typename
          id
          title
          alternateTitle
          description {
            description
          }
          category {
            title
            id
            backgroundColor
            fontColor
          }
          externalUrl
          slug
          featuredImage {
            gatsbyImageData
            title
            url
            description
          }
          infographicImage {
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
        ... on ContentfulComponentGeneric {
          id
          align
          background
          callsToAction {
            variant
            type
            link
            label
            id
            internalName
            internalLink
            contentful_id
          }
          kicker
          heading
          headingSize
          bodyCopy {
            raw
          }
          featuredImage {
            gatsbyImageData
            file {
              url
            }
            title
            description
            contentful_id
          }
        }
        ... on ContentfulBlogPost {
          __typename
          id
          title
          alternateTitle
          publishDate
          slug
          body {
            raw
          }
          description {
            description
          }
          category {
            backgroundColor
            fontColor
            title
            id
          }
          author {
            fullName
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulKnowledgeCenterPost {
          __typename
          id
          title
          alternateTitle
          slug
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          category {
            title
            id
          }
          body {
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                  description
                }
              }
            }
          }
        }
        ... on ContentfulNewsroomPost {
          __typename
          id
          alternateTitle
          title
          externalTitle
          publishDate
          slug
          description {
            description
          }
          category {
            title
            id
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulComponentTile {
          __typename
          internalName
          category {
            title
            id
          }
          heading
          alternateHeading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
      slide2Post {
        ... on ContentfulResource {
          __typename
          id
          title
          alternateTitle
          description {
            description
          }
          category {
            title
            id
          }
          externalUrl
          slug
          featuredImage {
            gatsbyImageData
            title
            url
            description
          }
          infographicImage {
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
        ... on ContentfulBlogPost {
          __typename
          id
          title
          alternateTitle
          publishDate
          slug
          body {
            raw
          }
          description {
            description
          }
          category {
            backgroundColor
            title
            id
          }
          author {
            fullName
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulKnowledgeCenterPost {
          __typename
          id
          title
          alternateTitle
          slug
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          category {
            title
            id
          }
          body {
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                  description
                }
              }
            }
          }
        }
        ... on ContentfulNewsroomPost {
          __typename
          id
          alternateTitle
          title
          externalTitle
          publishDate
          slug
          description {
            description
          }
          category {
            title
            id
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulComponentTile {
          __typename
          internalName
          category {
            title
            id
          }
          heading
          alternateHeading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
  }
`