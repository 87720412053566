import styled from "styled-components";
import { color } from "@atoms/colors"
import { font } from "@atoms/typography"

export const CardDeckWrapper = styled.div`
  background-color: ${color.grey[100]};
  padding: 0 30px;
  @media (min-width: 993px) {
    padding-bottom: 80px;
  }
`

export const Content = styled.div`
  max-width: 506px;
  margin: 0 auto;
  display: grid;
  row-gap: 32px;
  @media (min-width: 577px) {
    max-width: 770px;
  }
  @media (min-width: 993px) {
    max-width: 1170px;
  }
`

export const Heading = styled.span`
  ${font('display', 'xs', '700')}
  color: ${color.grey[900]};
`

export const CardDeckGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
  @media (min-width: 577px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 32px;
    column-gap: 30px;
  }
  @media (min-width: 993px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
