import styled, { css } from "styled-components"
import { color } from "../../../atoms/colors";
import { font } from "../../../atoms/typography";

export const HeroWrapper = styled.div`
  background-position: left bottom 200px!important;
  background-repeat: no-repeat!important;
  margin-top: 100px;
  background-color: ${color.grey[100]};
  padding: 0 30px 48px;
  background-size: cover;
  position: relative;
  @media (min-width: 769px) {
    padding: 0 30px 72px;
  }
  @media (min-width: 993px) {
    padding: 0 30px;
    background-position: left bottom!important;
  }
  .background-image {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    height: 70%;
    z-index: 0;
  }
`

export const Hero = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 506px;
  z-index: 1;
  position: relative;
  @media (min-width: 577px) {
    max-width: 770px;
  }
  @media (min-width: 993px) {
    max-width: 1170px;
  }
`

export const BreadCrumbFlex = styled.div`
  padding: 24px 0;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1170px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  a {
    text-decoration: none;
    span {
      ${font('text', 'sm', '400')}
    }
  }
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    ${font('text', 'sm', '700')}
    color: ${color.common.white};
    cursor: pointer;
    transition: color 200ms ease;
    &:hover {
      color: #dddddd;
    }
  }
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`

export const HeroContentWrap = styled.div`
  display: grid;
  gap: 40px;
  justify-content: center;
  text-align: center;
  padding: 24px 0 0;
  @media (min-width: 577px) {
    padding: 32px 0 0;
  }
  @media (min-width: 993px) {
    text-align: left;
    gap: 64px;
    padding: 40px 0 96px;
  }
`

export const TextSide = styled.div`
  display: grid;
  gap: 40px;
  height: fit-content;
  margin: auto 0;
`

export const HeadingWrap = styled.div`
  display: grid;
  gap: 24px;
  text-align: center;
  color: ${color.common.white};
`

export const Text = styled.p`
  ${font('text', 'reg', '400')}
  ${props => props && props?.bold && css`
    ${font('text', 'reg', '600')}
  `}
  @media (min-width: 993px) {
    ${font('text', 'lg', '400')}
    ${props => props && props?.bold && css`
      ${font('text', 'lg', '600')}
    `}
  }
`

export const Eyebrow = styled.span`
  ${font('kicker', 'reg', '900')}
  color: ${color.common.electricBlue};
`

export const Heading = styled.h1`
  color: ${color.common.white};
  ${font('display', 'md', '900')}
  @media (min-width: 577px) {
    ${font('display', 'lg', '900')}
  }
  @media (min-width: 993px) {
    ${font('display', 'xl', '900')}
  }
`

export const ImgWrap = styled.div`
  background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.36) 28.69%, rgba(255, 255, 255, 0.27) 100%);
  box-shadow: 0px 20px 30px rgba(16, 24, 40, 0.05);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  padding: 16px;
  margin: 0 auto;
  @media (min-width: 577px) {
    padding: 24px;
  }
  @media (min-width: 993px) {
    margin: auto 0;
  }
`

export const FeaturedItemWrapper = styled.div`
  max-width: 1170px;
  border-radius: 6px;
  transition: all 0.2s ease-in;
  box-shadow: 0px 4px 30px 0px rgba(54, 19, 81, 0.10);
  a {
    text-decoration: none;
  }
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
`

export const FeaturedContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  text-align: left;
  @media (min-width: 577px) {
    flex-direction: row;
  }
`

export const FeaturedText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border: 1px solid #E4E7EC;
  flex-shrink: 2;
  flex-grow: 1;
  border-radius: 0 0 6px 6px;
  background-color: ${color.common.white};
  @media (min-width: 577px) {
    border-radius: 6px 0 0 6px;
  }
  @media (min-width: 993px) {
    padding: 48px;
  }
`

export const FeaturedHeading = styled.h2`
  ${font("display", "xxs", "700")}
  color: ${color.grey[900]};
  @media (min-width: 993px) {
    ${font("display", "sm", "700")}
  }
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const FeaturedDescription = styled.p`
  ${font("text", "reg", "400")}
  color: ${color.grey[600]};
  @media (min-width: 577px) and (max-width: 992px) {
    display: none;
  }
  @media (min-width: 993px) {
    ${font("text", "reg", "400")}
  }
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const FeaturedPhoto = styled.div`
  width: 100%;
  border-radius: 6px 6px 0 0;
  min-height: 100%;
  overflow: hidden;
  flex-grow: 2;
  .gatsby-image-wrapper {
    aspect-ratio: 7 / 4;
    height: 100%;
    border-radius: 6px 6px 0 0;
    img {
      border-radius: 6px 6px 0 0;
    }
  }
  @media (min-width: 577px) {
    border-radius: 0 6px 6px 0;
    .gatsby-image-wrapper {
      border-radius: 0 6px 6px 0;
      img {
        border-radius: 0 6px 6px 0;
      }
    }
  }
`